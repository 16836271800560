import React from 'react';
//import HeroSectionAboutUs from '../components/HeroSectionAboutUs';
import Reparatur  from '../../components/reparatur';
import ReparaturMidsection from '../../components/ReparaturMidsection2';
import PanelWithBord from '../../components/PanelWithBord';
import CenteredDark from '../../components/FAQs/CenteredDark';

import LandingPage from '../../components/LandingPageGerate';

function smartphone() {
    return (
        <div>
            <LandingPage />
        </div>
    );
}

export default smartphone;
