import React from 'react';
//import HeroSectionAboutUs from '../components/HeroSectionAboutUs';
import Reparatur  from '../components/reparatur';
import ReparaturMidsection from '../components/ReparaturMidsection';
import PanelWithBord from '../components/PanelWithBord';
import CenteredDark from '../components/FAQs/CenteredDark';
import ReparaturMidsection2 from '../components/ReparaturMidsection2';
import SplitTwoTone from '../components/ContactUs/SplitTwoTone';
function Handy() {
    return (
        <div>
            <Reparatur />
            <ReparaturMidsection />
            <PanelWithBord />
            <ReparaturMidsection2 />
            <SplitTwoTone />

            < CenteredDark />
        </div>
    );
}

export default Handy;

